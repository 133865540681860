export const fcstVarConf =
{
  "validerror_air_temperature": {
    "badError": 1,
    "longName": "Temperature",
    "units": {
      "si": "K",
      "imperial": "degF",
      "metric": "degC"
    },
    "fromSi": {
      "imperial": "1.8",
      "metric": "1.0"
    },
    "obsTooHigh": "warmer",
    "obsTooLow": "colder"
  },
  "validerror_dew_point_temperature": {
    "badError": 2,
    "longName": "Dew Point",
    "units": {
      "si": "K",
      "imperial": "degF",
      "metric": "degC"
    },
    "fromSi": {
      "imperial": "1.8",
      "metric": "1.0"
    },
    "obsTooHigh": "more humid",
    "obsTooLow": "drier"
  },
  "validerror_air_pressure_at_mean_sea_level": {
    "badError": 20,
    "longName": "Sea Level Pres.",
    "units": {
      "si": "Pa",
      "imperial": "hPa",
      "metric": "hPa"
    },
    "obsTooHigh": "higher",
    "obsTooLow": "lower"
  },
  "validerror_wind_speed": {
    "badError": 1,
    "longName": "Wind Speed",
    "units": {
      "si": "m/s",
      "imperial": "mi/hr",
      "metric": "km/hr"
    },
    "obsTooHigh": "faster",
    "obsTooLow": "slower"
  }
}
