import React from 'react'
import { gql, useQuery } from "@apollo/client";
import { Circle, Tooltip } from 'react-leaflet';
import { fcstVarConf } from './conf/verification-variables';
import { tileToBBox } from 'global-mercator';
import { create, all } from 'mathjs';

const math = create(all, {});

export default function ForecastErrorTile({varname, validTime, vintageHr, zoom, excludedMetNetworks, selectedFcstModel, slippyTile, units}) {
  const badError = fcstVarConf[varname].badError;
  const tileBBox = tileToBBox(slippyTile);

  const FCST_ERROR_TILE = gql`
    {
      ${varname}_by_station_fcn(args: {
          fcst_age_hr_min: ${vintageHr[0]},
          fcst_age_hr_max: ${vintageHr[1]},
          fcst_domain: 1,
          fcst_time: "${validTime}",
          southwest_lon: ${tileBBox[0]},
          southwest_lat: ${tileBBox[1]},
          northeast_lon: ${tileBBox[2]},
          northeast_lat: ${tileBBox[3]}
          }, where: ${zoom < 8 ? `{station: {source: {_nin: ${JSON.stringify(excludedMetNetworks)}}}}` : '{}'} ) {
        fcst_domain
        fcst_time
        fcst_val_avg
        obs_val_avg
        station {
          station
          source
          geom
        }
        fcst_err_avg
        count
      }
    }
  `
  const displayUnits = fcstVarConf[varname].units[units];
  const siUnits = fcstVarConf[varname].units.si;

  const { loading, error, data } = useQuery(FCST_ERROR_TILE);
  if (loading) return null;
  if (error) return <p>Error in GraphQL query... ${error.toString()}</p>
  const zoomForDetails = <Tooltip>Zoom for more details</Tooltip>

  return (
    data[varname + '_by_station_fcn'].map(({ fcst_time, station, fcst_err_avg, count, fcst_val_avg, obs_val_avg }) => (
      <Circle key={selectedFcstModel + ' ' + fcst_time + ' ' + station.station + ' ' + vintageHr[0] + ' ' + vintageHr[1] + ' ' + fcst_err_avg + ' ' + count}
        center={[station.geom.coordinates[1], station.geom.coordinates[0]]}
        color={fcst_err_avg >= 0 ? "blue" : "red"} radius={Math.abs(fcst_err_avg) / badError * 1000} >
        {zoom > 6 ?
          <Tooltip>
            Station: {station.station}<br />
            Source: {station.source}<br />
            Fcst: {Math.abs(math.unit(fcst_val_avg, siUnits).to(displayUnits).toNumber()).toFixed(2)} {displayUnits.replace(/^deg/, '°')}<br />
            Obs: {Math.abs(math.unit(obs_val_avg, siUnits).to(displayUnits).toNumber()).toFixed(2)} {displayUnits.replace(/^deg/, '°')}<br />
            <span className={fcst_err_avg < 0 ? 'obs-too-high' : 'obs-too-low'}>
              Reality was {fcstVarConf[varname][fcst_err_avg < 0 ? 'obsTooHigh' : 'obsTooLow']}<br />
            </span>
            Error: {fcstVarConf[varname].fromSi ? (fcst_err_avg * fcstVarConf[varname].fromSi[units]).toFixed(2) : math.unit(fcst_err_avg, siUnits).to(displayUnits).toNumber().toFixed(2)} {displayUnits.replace(/^deg/, '°')}<br />
            Count: {count}
          </Tooltip>
        : zoomForDetails }
      </Circle>
    ))
  );
}