import { React, useState, useEffect } from 'react'
import './App.css'
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client"
import { HttpLink } from 'apollo-link-http'
import ForecastMap from './ForecastMap'
import './styles.css'
import ForecastModelSelector from './ForecastModelSelector'
import ForecastVariableSelector from './ForecastVariableSelector'
import ForecastVintageSlider from './ForecastVintageSlider'
import ForecastStatistics from './ForecastStatistics'
import ForecastLegend from './ForecastLegend'
import UnitsSelect from './UnitsSelect'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import moment from 'moment-timezone'
import leaflet from 'leaflet'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Nav, Spinner } from 'react-bootstrap'
import mixpanel from 'mixpanel-browser';
import { fcstModelConf } from './conf/forecast-models'

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);
mixpanel.identify();

export default function App() {
  const [selectedFcstModel, setSelectedFcstModel] = useState(Object.keys(fcstModelConf)[0])
  const [selectedFcstVar, setSelectedFcstVar] = useState('validerror_air_temperature')
  const [vintageHr, setVintageHr] = useState([12, 36])
  const [validTime, setValidTime] = useState(calcFcstAvailTime(selectedFcstModel))
  const [maxBounds, setMaxBounds] = useState(leaflet.latLngBounds(
    leaflet.latLng(50, -126),
    leaflet.latLng(24, -66)
  ))
  const [bBox, setBBox] = useState(maxBounds)
  const [mapLoading, setMapLoading] = useState(false)
  const [excludedMetNetworks, setExcludedMetNetworks] = useState(['APRSWXNET', 'MesoWest'])
  const [apolloClient, setApolloClient] = useState(undefined)
  const [units, setUnits] = useState('imperial')
  const [renderMapTiles, setRenderMapTiles] = useState(true)
  const [zoom, setZoom] = useState(4)

  useEffect(() => {
    function handleFcstModelChange(selectedFcstModel, fcstModelConf) {
      const link = new HttpLink({
        uri: fcstModelConf[selectedFcstModel].graphqlUrl,
        headers: 'Access-Control-Allow-Origin',
      })
  
      // update time for models liks GEM that might be only available every 3 hours
      setValidTime(calcFcstAvailTime(selectedFcstModel));
  
      setApolloClient(new ApolloClient({
        link, cache: new InMemoryCache(), fetchOptions: { mode: 'no-cors' },
        headers: { 'Accept-Encoding': 'gzip', 'Access-Control-Allow-Origin': '*' }
      }))
  
      mixpanel.track('MODEL_CHANGE', { selectedFcstModel });
    }
  
    handleFcstModelChange(selectedFcstModel, fcstModelConf);
    const interval = setInterval(() => setValidTime(calcFcstAvailTime(selectedFcstModel)), 1000 * 60);
    mixpanel.track('APP_LOAD');
    return (interval) => {
      clearInterval(interval);
    }
  }, [selectedFcstModel]);

  function calcFcstAvailTime(fcstModel) {
    // New obs data is pulled in at 6 minutes after the hour, so wait 1 minute to update
    let t = moment().utc()
    t = t.minutes() > 59 ? t.add('hour', 1) : t

    // go back to the last valid time for 3-hourly forecast like GEM
    if (fcstModelConf[fcstModel].hourly !== undefined) {
      t = t.subtract('hour', t.utc().hours() % fcstModelConf[fcstModel].hourly)
    }
    return t.set({ minute: 0, second: 0, millisecond: 0 }).toISOString()
  }

  return (
    apolloClient ?
      <ApolloProvider client={apolloClient}>
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand href="https://weathertactics.io/">
            <img
              src="/wxtactics-white-logo.svg"
              width="100"
              className="d-inline-block align-top"
              alt="Weather Tactics logo"
            />
          </Navbar.Brand>
          <ForecastModelSelector
            fcstModelConf={fcstModelConf}
            selectedFcstModel={selectedFcstModel}
            setSelectedFcstModel={setSelectedFcstModel} />
          <ForecastVariableSelector selectedFcstVar={selectedFcstVar}
            onForecastVariableChange={setSelectedFcstVar} />
          <Nav className="ml-auto">
            <UnitsSelect units={units} setUnits={setUnits} />
          </Nav>
        </Navbar>
        <Container fluid>
          <Row>
            <Col sm="12" md="5">
              <strong>Valid:</strong> {moment(validTime).format('YYYY-MM-DD HH:mmZ')}<br />
              <ForecastStatistics varname={selectedFcstVar}
                validTime={validTime}
                vintageHr={vintageHr}
                bBox={bBox}
                excludedMetNetworks={excludedMetNetworks}
                units={units} />
              <ForecastLegend selectedFcstVar={selectedFcstVar} />
            </Col>
            <Col sm="0" md="1">
              {mapLoading ? <Spinner animation="border" variant="danger" /> : ''}
            </Col>
            <Col id="forecast-vintage-container" sm="12" md="6">
              <strong>Forecast Vintage</strong>
              <ForecastVintageSlider
                vintageHr={vintageHr}
                setVintageHr={setVintageHr}
                selectedFcstModel={selectedFcstModel}
                setRenderMapTiles={setRenderMapTiles} />
            </Col>
          </Row>
          <Row>
          </Row>
          <Row id="map-container">
            <ForecastMap selectedFcstVar={selectedFcstVar}
              validTime={validTime}
              vintageHr={vintageHr}
              setZoom={setZoom}
              maxBounds={maxBounds}
              bBox={bBox}
              setBBox={setBBox}
              excludedMetNetworks={excludedMetNetworks}
              setExcludedMetNetworks={setExcludedMetNetworks}
              selectedFcstModel={setSelectedFcstModel}
              units={units}
              renderMapTiles={renderMapTiles}
              zoom={zoom} />
          </Row>
          <Row>
            <Col md="6">{excludedMetNetworks.length > 0 ? `*Excluded networks: ${excludedMetNetworks.toString()} (zoom to see more)` : 'All MADIS networks shown'}</Col>
            <Col md="6">
              <p className="text-right">
                © 2020-{new Date().getFullYear()} Weather Tactics LLC,&nbsp;
                <a href="https://www.surveymonkey.com/r/JKBFZDJ">User Survey,</a>&nbsp;
                <a href="mailto:mike@weathertactics.io">Contact</a>&nbsp;
              </p>
            </Col>
          </Row>
        </Container>
      </ApolloProvider> : null
  )
}
