export const fcstModelConf =
{
  "gfs-0p25": {
    "namePretty": "GFS 0.25°",
    "maxFcstTimeHours": 96,
    "graphqlUrl": "https://api.gfs-0p25.weathertactics.io/v1/graphql"
  },
  "nam-12km": {
    "namePretty": "NAM 12km",
    "maxFcstTimeHours": 84,
    "graphqlUrl": "https://api.nam-12km.weathertactics.io/v1/graphql"
  },
  "gem-15km": {
    "namePretty": "GEM 15km",
    "maxFcstTimeHours": 96,
    "graphqlUrl": "https://api.gem-15km.weathertactics.io/v1/graphql",
    "hourly": 3
  }
}
