import React from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import Col from 'react-bootstrap/Col'
import './ForecastVintageSlider.css'
import { fcstModelConf } from './conf/forecast-models'

export default function ForecastVintageSlider({ selectedFcstModel, vintageHr, setVintageHr, setRenderMapTiles }) {
  let marks = {}
  const maxFcstTimeHours = fcstModelConf[selectedFcstModel].maxFcstTimeHours
  for (let i = maxFcstTimeHours; i >= 0; i -= 12) {
    marks[`${-1 * i}`] = i === maxFcstTimeHours / 2 ? `${i} hours` : `${i}`
  }
  
  function handleVintageHourChange(value) {
    setRenderMapTiles(false)
    setVintageHr([-value[1], -value[1] + 24])
    setRenderMapTiles(true)
  }

  return (
    <Col>
      <Slider
        range
        min={-maxFcstTimeHours}
        max={0}
        step={24}
        marks={marks}
        pushable={true}
        onChange={(value) => handleVintageHourChange(value)}
        value={[-vintageHr[1], -vintageHr[0]]}
      />
    </Col>
  )
}
