import React from 'react'
import { fcstVarConf } from './conf/verification-variables';

export default function ForecastLegend({ selectedFcstVar }) {
  if (!selectedFcstVar) return null
  return(
    <div>
      <span className="obs-too-high">⬤</span><span>Reality {fcstVarConf[selectedFcstVar].obsTooHigh}</span>&nbsp;
      <span className="obs-too-low">⬤</span><span>Reality {fcstVarConf[selectedFcstVar].obsTooLow}</span>
    </div>
  )
}
