import React, { Component } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { fcstVarConf } from './conf/verification-variables';

class ForecastVariableSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  render() {
    const { selectedFcstVar } = this.props
    const varList = Object.keys(fcstVarConf).map((key) =>
      <Dropdown.Item key={key} eventKey={key}>{fcstVarConf[key].longName}</Dropdown.Item>
    )
    return (
      <DropdownButton id="dropdown-basic-button" title={fcstVarConf[selectedFcstVar].longName} variant="secondary"
        onSelect={(eventKey) => this.props.onForecastVariableChange(eventKey) }>
          {varList}
      </DropdownButton>
    )
  }
}

export default ForecastVariableSelector
