import { React } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'

export default function ForecastModelSelector({ fcstModelConf, selectedFcstModel, setSelectedFcstModel }) {
  const modelList = Object.keys(fcstModelConf).map((key) =>
    <Dropdown.Item key={key} eventKey={key}>{fcstModelConf[key].namePretty}</Dropdown.Item>
  )
  return (
    <DropdownButton id="dropdown-basic-button" title={fcstModelConf[selectedFcstModel].namePretty} variant="secondary"
      onSelect={(eventKey) => setSelectedFcstModel(eventKey)}>
      {modelList}
    </DropdownButton>
  )
}
