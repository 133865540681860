import React, { Component, PureComponent } from 'react'
import { all } from 'slippy-grid'
import ForecastErrorTile from './ForecastErrorTile'
import { validateLngLat, validateZoom } from 'global-mercator'
import { useMapEvents } from 'react-leaflet/hooks'

export default function ForecastErrors({ varname, validTime, vintageHr, bBox, setBBox, zoom, setZoom, excludedMetNetworks, setExcludedMetNetworks, selectedFcstModel, units, renderMapTiles }) {
  const map = useMapEvents({
    zoomend: () => {
      if (zoom < 8) {
        setExcludedMetNetworks(['APRSWXNET', 'MesoWest'])
      }
      else {
        setExcludedMetNetworks([])
      }
    },
    zoom: () => {
      setZoom(map.getZoom())
      setBBox(map.getBounds())
    },
  })

  // Do not render as the bounding box is being initialized
  if (bBox === undefined || zoom === undefined) return null;

  const northEast = [bBox.getEast(), bBox.getNorth()]
  const southWest = [bBox.getWest(), bBox.getSouth()]
  validateLngLat(northEast);
  validateLngLat(southWest);
  validateZoom(zoom);
  const zoomToUse = zoom > 8 ? 4 : 6;
  const grid = all(southWest.concat(northEast), zoomToUse, zoomToUse);

  return (
    grid.map(slippyTile => (
    renderMapTiles ? <React.Fragment key={slippyTile + ' ' + validTime + ' ' + varname}>
      <ForecastErrorTile varname={varname} validTime={validTime} vintageHr={vintageHr}
        zoom={zoom} excludedMetNetworks={excludedMetNetworks} selectedFcstModel={selectedFcstModel}
        slippyTile={slippyTile} units={units} />
    </React.Fragment> : null
    // renderMapTiles && this.state.pruneCluster ?
    //   <React.Fragment key={slippyTile + ' ' + validTime + ' ' + varname}>
    //     <PruneClusterReact pruneCluster={this.state.pruneCluster} varname={varname} validTime={validTime} vintageHr={vintageHr}
    //       zoom={zoom} excludedMetNetworks={excludedMetNetworks} selectedFcstModel={selectedFcstModel}
    //       slippyTile={slippyTile} units={units} mapRef={mapRef} />
    //   </React.Fragment>
    //   : null
    )
    )
  )
}
