import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import './UnitsSelect.css';

export default function UnitsSelect({ units, setUnits}) {
  return (
    <Form onChange={(event) => setUnits(event.target.id)} className="form">
      <div key="units-selector" className="mb-3">
        <Form.Check
          type="radio"
          id="imperial"
          label="imperial"
          checked={units === 'imperial'}
          readOnly
        />
        <Form.Check
          type="radio"
          id="metric"
          label="metric"
          checked={units === 'metric'}
          readOnly
        />
      </div>
    </Form>
  );
}
