import React, { useEffect, useRef } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import ForecastErrors from './ForecastErrors'
import 'leaflet/dist/leaflet.css'
import { bboxToCenter } from 'global-mercator'

export default function ForecastMap({ validTime, onValidTimeChange, vintageHr, selectedFcstVar, bBox, setBBox, setExcludedMetNetworks, selectedFcstModel, units, renderMapTiles, zoom, onBoundingBoxChange, excludedMetNetworks, setZoom, maxBounds }) {
    const mapRef = useRef()

    useEffect(() => {
      if (mapRef.current !== null) {
        setBBox(mapRef.current.getBounds())
      }
    }, [ mapRef ])
  
    return (
      <MapContainer
        id={"map"}
        center={[37, -96]}
        zoom={zoom}
        setZoom={setZoom}
        maxBounds={maxBounds}
        ref={mapRef}
        onMoveEnd={onBoundingBoxChange}
        minZoom={5}
        maxZoom={10}>
        <ForecastErrors varname={selectedFcstVar}
            validTime={validTime}
            onValidTimeChange={onValidTimeChange}
            vintageHr={vintageHr}
            bBox={bBox}
            setBBox={setBBox}
            excludedMetNetworks={excludedMetNetworks}
            setExcludedMetNetworks={setExcludedMetNetworks}
            zoom={zoom}
            setZoom={setZoom}
            selectedFcstModel={selectedFcstModel}
            units={units}
            renderMapTiles={renderMapTiles}
            mapRef={mapRef} />
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    )
}