import React from 'react'
import { gql, useQuery } from "@apollo/client";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { fcstVarConf } from './conf/verification-variables';
import { create, all } from 'mathjs';

const math = create(all, {});

export default function ForecastStatistics({ varname, validTime, vintageHr, bBox, excludedMetNetworks, units }) {
  const GET_FCST_STATS = gql`
      {
        ${varname}_by_station_fcn_aggregate(args: {
          fcst_age_hr_min: ${vintageHr[0]},
          fcst_age_hr_max: ${vintageHr[1]},
          fcst_domain: 1,
          fcst_time: "${validTime}",
          southwest_lon: ${bBox.getWest()},
          southwest_lat: ${bBox.getSouth()},
          northeast_lon: ${bBox.getEast()},
          northeast_lat: ${bBox.getNorth()}
        }, where: {station: {source: {_nin: ${JSON.stringify(excludedMetNetworks)}}}}) {
          aggregate {
            sum {
              fcst_err_avg
            }
            count
          }
        }
      }`
  const { loading, error, data } = useQuery(GET_FCST_STATS);
  if (loading) return <p>Recalculating statistics...</p>
  if (error) return <p>Error in GraphQL query...</p>

  const displayUnits = fcstVarConf[varname].units[units]
  const siUnits = fcstVarConf[varname].units.si
  const fromSi = fcstVarConf[varname].fromSi
  const fcst_err_avg = data[varname + '_by_station_fcn_aggregate'].aggregate.sum.fcst_err_avg
  const count = data[varname + '_by_station_fcn_aggregate'].aggregate.count

  return (
    <Row>
      <Col><strong>Avg Error:</strong> {fromSi ? (fcst_err_avg / count * fromSi[units]).toFixed(2) : math.unit(fcst_err_avg / count, siUnits).to(displayUnits).toNumber().toFixed(2)}&nbsp;{displayUnits.replace(/^deg/, '°')}</Col>
      <Col><strong>Stations:</strong> {count}{excludedMetNetworks.length > 0 ? '*' : ''}</Col>
    </Row>
  );
}
